<template>
  <div class="side-bar">
    <ul class="tool-bar">
      <li ref="serviceRef" @mouseenter="serviceImg='tool-kefu'" @mouseleave="serviceImg='icon-kefu'">
        <transition name="slide-up">
          <img :key="serviceImg" :src="`https://static.ppkao.com/www/images/${serviceImg}.png`" alt="联系客服">
        </transition>
      </li>
      <li ref="wxRef" @mouseenter="wxImg='tool-xcx'" @mouseleave="wxImg='icon-wechat'">
        <transition name="slide-up">
          <img :key="wxImg" :src="`https://static.ppkao.com/www/images/${wxImg}.png`" alt="小程序">
        </transition>
      </li>
      <li @mouseenter="desktopImg='tool-desktop'" @mouseleave="desktopImg='icon-desktop'">
        <a href="/download" title="桌面端搜题下载" target="_blank">
          <transition name="slide-up">
            <img :key="desktopImg" :src="`https://static.ppkao.com/www/images/${desktopImg}.png`" alt="桌面端下载">
          </transition>
        </a>
      </li>
      <li @mouseenter="topImg='tool-top'" @mouseleave="topImg='icon-top'" @click="backUp">
        <transition name="slide-up">
          <img :key="topImg" :src="`https://static.ppkao.com/www/images/${topImg}.png`" alt="回到顶部">
        </transition>
      </li>
    </ul>
    <el-popover
      ref="popoverRef"
      :virtual-ref="serviceRef"
      trigger="hover"
      placement="right"
      :effect="effect"
      virtual-triggering
    >
      <div class="qrcode-box">
        <img src="https://static.ppkao.com/public/wxqrcode/pptiku.jpg" alt="联系客服"/>
        <span>微信扫一扫关注公众号后联系客服</span>
      </div>
    </el-popover>
    <el-popover
      ref="popoverRef"
      :virtual-ref="wxRef"
      trigger="hover"
      placement="right"
      virtual-triggering
      :effect="effect"
    >
      <div class="qrcode-box">
        <img src="https://static.ppkao.com/www/images/zantiku.jpg" alt="扫码练习"/>
        <span>微信扫码免费搜题</span>
      </div>
    </el-popover>
  </div>
</template>

<script setup>
const nuxtApp = useNuxtApp();
const effect = computed(() => nuxtApp.$darkMode?.isDarkMode?.value ? 'dark' : 'light');
const serviceImg = ref('icon-kefu');
const wxImg = ref('icon-wechat');
const desktopImg = ref('icon-desktop');
const topImg = ref('icon-top');

const serviceRef = ref()
const wxRef = ref()

const backUp = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
}
</script>
<style lang="scss">
.qrcode-box {
  @include flex-col-center;
  img {
    width: 120px;
  }
  span {
    text-align: center;
    margin-top: 6px;
    @include font(var(--title-color))
  }
}
</style>

<style scoped lang="scss">
.side-bar {
  width: 100%;
  position: fixed;
  z-index: 100;
  left: calc(50% + 600px + 28px); /* 精确定位到旁边 28px 的位置 */
  top: 50%;
  margin-top: -120px;

  .tool-bar {
    .active {
      background-color: #E4F1FF;
    }
    li {
      width: 54px;
      height: 54px;
      background-color: #FFFFFF;
      border-radius: 10px;
      @include flex-center;
      margin-bottom: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      opacity: var(--bg-opacity);
      a {
        width: 100%;
        @include flex-center;
        height: 100%;
        position: relative;
      }
      img {
        position: absolute;
        transition: transform 0.3s ease;
      }
      &:hover {
        background-color: #EDEDED;
      }
      &:nth-child(1) img {
        width: 27px;
      }
      &:nth-child(2) {
        &:hover {
          background-color: #e0f9e3;
        }
      }
      &:nth-child(3):hover {
        background-color: #E4F1FF;
      }
    }
  }
}
.slide-up-enter-active,
.slide-up-leave-active {
  transition: transform 0.25s ease-out;
}

.slide-up-enter-from {
  transform: translateY(200%);
}

.slide-up-enter-to {
  transform: translateY(0);
}

.slide-up-leave-from {
  transform: translateY(0);
}

.slide-up-leave-to {
  transform: translateY(-200%);
}
</style>
